.InfoObjectStatsPanel .Table
{
  width: 100%;
  font-size: 13px;
}
.InfoObjectStatsPanel .Cell
{
  padding: 4px;
  vertical-align: top;
}
.InfoObjectStatsPanel .Cell:first-child
{
  padding: 0 4px 10px;
}
.InfoObjectStatsPanel .Icon
{
  font-size: 12px;
}
