.Screen
{
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
}
.Screen:has(.MobileHeader)
{
  padding-top: var(--header-size);
}
.Screen:has(.MobileMenu)
{
  padding-bottom: var(--menu-size);
}
.Screen > .LoadingIndicator
{
  font-size: 40px;
}
.Screen > .Main
{
  overflow-x: hidden;
}
