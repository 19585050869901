.MobileHeader
{
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 12px;
  align-items: center;
  background-color: #fff;
  padding: 0 12px;
  box-sizing: border-box;
  /*border-bottom: 1px solid rgb(0 0 0 / 5%);*/ /* todo show border on scroll */
}
.MobileHeader .Heading
{
  text-align: center;
}
.MobileHeader .Search
{
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.MobileHeader .Button[type=submit],
.MobileHeader .Button[type=search]
{
  font-size: 10px;
  position: absolute;
  right: 0;
  padding: 2px;
}
.MobileHeader .Button[aria-haspopup],
.MobileHeader .Button[type=back]
{
  font-size: 12px;
}
.MobileHeader .TextBox,
.MobileHeader .NativeInputBox,
.MobileHeader .SuggestBox
{
  width: 100%;
  font-size: 14px;
}
.MobileHeader .TextBoxControl,
.MobileHeader .NativeInputBoxControl
{
  padding: 3px var(--gap);
  padding-right: 24px;
}
