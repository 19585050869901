.PlaceCategoryGroup
{
  box-sizing: border-box;
  font-size: 10px;
  line-height: 1.4;
}
.PlaceCategoryGroup > .HeadBlock
{
  display: flex;
  justify-content: space-between;
  padding: 0 6px var(--gap);
  font-weight: 400;
}
.PlaceCategoryGroup > .HeadBlock > .Label
{
  line-height: inherit;
  white-space: nowrap;
}
.PlaceCategoryGroup > .HeadBlock > .Button
{
  font-size: inherit;
  line-height: inherit;
}
.PlaceCategoryGroup > .Control
{
  grid-area: control;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding: 12px 4px;
}
