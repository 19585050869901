.TripPlaceAddButton
{
  display: block;
}
.TripPlaceAddButton > .Control
{
  border: none;
  padding: var(--gap);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 15px;
}
.TripPlaceAddButton .Label
{
  font-weight: 500;
}
.TripPlaceAddButtonPopup
{
  align-items: flex-end;
  padding-bottom: 80px;
}
.TripPlaceAddButtonPopup .PopupContent
{
  width: 100%;
  max-width: 300px;
}
