.TripRoutesBlock
{
  --padding-left: 12px;
  --route-color: var(--dim-color);
  --transition-duration: 300ms;
  --tablist-height: 65px;
  --item-height: 26px;
  --item-margin-left: 10px;

  display: grid;
  grid-template-rows: var(--tablist-height) calc(100% - var(--tablist-height));
  overflow-x: hidden;
  position: relative;
}
.TripRoutesBlock::before
{
  content: '';
  position: absolute;
  top: var(--tablist-height);
  right: 0;
  left: 0;
  height: calc(var(--item-height) / 2);
  background: linear-gradient(to bottom, #fff, transparent);
  z-index: 1;
}
.TripRoutesBlock .TripDateList,
.TripRoutesBlock .TripRoutesGroup
{
  transition: transform var(--transition-duration);
}
.TripRoutesBlock .TripRouteHead
{
  align-self: flex-end;
  width: min(100vw, var(--max-width));
}
.TripRoutesBlock .TripDateList
{
  display: none;
  align-self: flex-end;
}
.TripRoutesBlock.transition .TripDurationItem,
.TripRoutesBlock.transition .TripPlaceItem .Button[type=menu]
{
  transition: opacity var(--transition-duration);
}
.TripRoutesBlock.move .TripPlaceItem .Button[type=menu]
{
  opacity: 0;
}
.TripRoutesBlock:has([aria-grabbed=true]) .TripDurationItem,
.TripRoutesBlock:has([aria-grabbed=true]) .TripServicesItem,
.TripRoutesBlock:has([aria-grabbed=true]) .TripPlaceItem::after,
.TripRoutesBlock:has([aria-grabbed=true]) .TripPlaceItem .Button[type=menu]
{
  opacity: 0;
}
