.ProfileItemDialog .DialogBody
{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.ProfileItemDialog .DialogBody > .Block
{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ProfileItemDialog .Form .DialogBody
{
  justify-content: space-between;
}
.ProfileItemDialog .Button[type=submit]
{
  display: block;
  font-size: inherit;
  width: auto;
}
.ProfileItemDialog .Button[type=submit] > .Control
{
  padding: 8px;
}
.ProfileItemDialog .Widget
{
  font-size: inherit;
}
.ProfileItemDialog .TextBox,
.ProfileItemDialog .SuggestBox
{
  width: auto;
  display: block;
}
.ProfileItemDialog .Widget[aria-hidden=true]
{
  display: none;
}
.ProfileItemDialog .TextBox[aria-multiline=true]
{
  font-size: 12px;
}
.ProfileItemDialog .TextBox[aria-multiline=true] .TextBoxInput
{
  min-height: 8em;
}
.ProfileItemDialog .ListItem
{
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-left: var(--gap);
}
.ProfileItemDialog .ListItem:not(:first-child)
{
  border-top: var(--control-border);
}
.ProfileItemDialog .ListItem .Button
{
  padding: 10px;
}
.ProfileItemDialog .ListItem .Icon
{
  font-size: 11px;
  align-self: center;
}
.ProfileItemDialog[aria-busy=true] .ListItem
{
  opacity: .5;
}
