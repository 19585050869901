.ProfileCountryItem a[href]
{
  overflow: visible;
}
.ProfileCountryItem .ListItem
{
  display: inline-block;
  margin: 16px 14px 0 0;
}
.ProfileCountryItem .Link,
.ProfileCountryItem .CountryFlag
{
  display: block;
}
.ProfileCountryItem .List[aria-hidden=true]
{
  display: none;
}
.ProfileCountryItem > .Button
{
  padding: 4px;
  align-self: flex-start;
  height: 24px;
}
.ProfileCountryItem > .Button .Icon
{
  font-size: 8px;
}
.ProfileCountryItem.open > .Button .Icon
{
  transform: rotate(180deg);
}
