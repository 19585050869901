.TripPlaceButton
{
  font-size: inherit;
  overflow: hidden;
  white-space: normal;
}
.TripPlaceButton .Icon
{
  transform: rotate(45deg);
  font-size: 12px;
}
.TripPlaceButton .Label
{
  max-width: 100%;
  font-weight: 600;
}
.TripPlaceButton > .Control
{
  border: none;
  padding: 0 var(--control-padding-h);
  min-width: auto;
}
.TripPlaceButton[aria-pressed=true] > .Control
{
  color: inherit;
  background-color: var(--action-color);
}
.TripPlaceButton[aria-pressed=true]:active > .Control
{
  background-color: var(--action-active-bg-color);
}
