.TripObjectCard
{
  color: #fff;
  position: relative;
}
.TripObjectCard .Photo::after
{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / .4);
}
.TripObjectCard > .Inner
{
  position: absolute;
  bottom: 0;
  padding: 20px 16px;
}
.TripObjectCard .TripDaySpan
{
  vertical-align: middle;
}
.TripObjectCard .Heading
{
  font-size: 27px;
  line-height: 32px;
  font-weight: 500;
  margin: var(--gap) 0;
}
