.LocalityCard .Photo,
.LocalityCard .PlacePhoto
{
  height: 130px;
  font-size: 30px;
}
.LocalityCard .PlacePhoto
{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.LocalityCard .FootBlock
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap);
  padding: 6px var(--gap);
}
.LocalityCard .LeftBlock
{
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.LocalityCard .RightBlock
{
  display: flex;
  align-items: center;
  gap: 10px;
}
.LocalityCard .LocalityName
{
  font-weight: 500;
}
.LocalityCard .LocalityCountryName
{
  font-size: 10px;
  font-weight: 300;
}
.LocalityCard .LocalityCoords
{
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
}
.LocalityCard .CountryFlag
{
  width: 38px;
  height: 26px;
}
.LocalityCard .LocalityCountryEmblem
{
  width: 28px;
  height: 28px;
  background-size: contain;
  background-color: transparent;
}
