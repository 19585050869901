.TripRoutePanel
{
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  gap: 26px;
  min-width: min(100vw, var(--max-width));
  max-width: 100vw;
  height: 100%;
  position: relative;
  padding: calc(var(--item-height, 36px) / 2) 12px 12px;
  box-sizing: border-box;
  overflow-y: auto;
  user-select: none;
}
.TripRoutePanel::-webkit-scrollbar
{
  display: none;
}
.TripRoutePanel:has([aria-grabbed=true])
{
  overflow-y: hidden;
}
.TripRoutePanel .TripPlaceAddButton
{
  margin-top: 12px;
}
.TripRoutePanel .HeadBlock
{
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.TripRoutePanel .TripMenuButton
{
  font-size: 16px;
  padding: 8px;
}
.TripRoutePanel[aria-busy=true] .TripPlaceItem
{
  transition: none;
}
