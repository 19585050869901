.PersonalInfoBlock dt
{
  font-size: 10px;
}
.PersonalInfoBlock dt:not(:first-child)
{
  margin-top: 16px;
}
.PersonalInfoBlock dd
{
  margin: 4px 0;
  padding-left: 6px;
}
