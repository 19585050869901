.InfoPlaceAboutPanel
{
  overflow-x: hidden;
}
.InfoPlaceAboutPanel .Article
{
  padding: 12px var(--block-padding-h);
}
.InfoPlaceAboutPanel .Status
{
  margin: 14px 0;
}
