.MobileMenu
{
  display: grid;
  /*grid-template-columns: 1fr 1fr 1fr 1fr 1fr;*/
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-top: 1px solid rgb(0 0 0 / 5%);
  background-color: #fff;
  box-sizing: border-box;
}
.MobileMenu .NavMenuItem
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  gap: 4px;
}
.MobileMenu .NavMenuItem .Label
{
  margin: 0;
  white-space: nowrap;
}
