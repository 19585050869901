.TripPlaceInfoCard .Photo
{
  height: 344px;
}
.TripPlaceInfoCard .TripDaySpan
{
  vertical-align: middle;
}
.TripPlaceInfoCard .Time
{
  vertical-align: middle;
  margin-left: 8px;
}
