.DialogMenu
{
  display: block;
  margin: var(--gap) 0;
  font-size: inherit;
}
.DialogMenu .Separator
{
  height: 1px;
  margin: var(--gap) 0;
  background-color: #e7e7e7;
}
.DialogMenu .MenuItem
{
  padding: var(--gap) 4px;
  line-height: 1.36;
  font-size: inherit;
}
.DialogMenu a.MenuItem:not([href])
{
  color: var(--dim-color);
}
