.PlacePhotoGroup
{
  --photo-max-width: min(100vw, var(--max-width));

  /*height: calc(var(--photo-max-width) / 4 * 3);*/
  height: 344px;
  display: flex;
  padding: 0;
  margin-top: 0;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}
.PlacePhotoGroup::-webkit-scrollbar
{
  display: none;
}
.PlacePhotoGroup .PlacePhoto
{
  max-width: var(--photo-max-width);
  height: 100%;
  scroll-snap-align: center;
  border-radius: 0;
}
.PlacePhotoGroup .PlacePhoto .Icon
{
  font-size: 50px;
  width: var(--photo-max-width);
}
