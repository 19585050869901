.LegalConfirmationBlock
{
  font-size: inherit;
  display: block;
}
.LegalConfirmationBlock a[href]
{
  color: inherit;
  font-weight: 500;
  text-decoration: underline;
}
