.LocalityBox[aria-expanded=true] > .Control
{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.LocalityBox .SuggestBoxPopup
{
  padding-top: 0;
}
.LocalityBox .SuggestBoxPopup > .PopupContent
{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.LocalityBox .SuggestBoxPopup .ListBoxControl
{
  border-top: 1px solid #e7e7e7;
  border-radius: 0;
  padding: 0;
}
.LocalityBox .SuggestBoxPopup[aria-hidden]
{
  transform: none;
}
