.ProfilePhotoSelectDialog > .PopupContent
{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
}
.ProfilePhotoSelectDialog .Button
{
  font-size: inherit;
  font-weight: 500;
}
.ProfilePhotoSelectDialog .ButtonControl
{
  padding: 6px;
}
