.TripsFeed
{
  display: grid;
  grid-gap: calc(2 * var(--gap));
}
.TripsFeed .Status
{
  font-size: 20px;
  color: var(--dim-color);
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}
