.Icon
{
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  width: 2em;
  height: 2em;
}
.Icon::before
{
  content: '';
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  width: 2em;
  height: 2em;
  margin: 0;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
