.InfoObjectNav
{
  padding: 12px;
}
.InfoObjectNav .Heading
{
  margin-bottom: 20px;
}
.InfoObjectNav .Group
{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px 16px;
  margin-top: 12px;
}
