.InfoObjectDialog .DialogBody
{
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 0 0 20px;
}
.InfoObjectDialog .TabList
{
  display: flex;
  gap: var(--gap);
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  padding: 12px;
  line-height: 1.1;
  font-weight: 500;
  height: 60px;
  box-sizing: border-box;
}
.InfoObjectDialog .TabList::-webkit-scrollbar
{
  display: none;
}
.InfoObjectDialog .Tab > .Control
{
  color: var(--accent-color);
  border-color: transparent;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
.InfoObjectDialog .Tab[aria-selected=true] > .Control
{
  background: var(--accent-color);
  color: var(--accent-text-color);
}
.InfoObjectDialog .TabPanel
{
  padding: 8px 12px;
}
.InfoObjectDialog .TabPanel .LoadingIndicator
{
  padding: 50px;
}
.InfoObjectDialog .TabPanel > .Heading
{
  margin: 8px 0 16px;
}
