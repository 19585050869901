.TravelModeOption
{
  display: inline-block;
  width: auto;
  font-size: 12px;
  padding: 7px;
  border-color: transparent;
  border-radius: 50%;
}
.TravelModeOption[aria-selected=true]
{
  background-color: #e7e7e7;
}
