.PlaceActionsBlock
{
  display: flex;
  grid-gap: var(--gap);
}
.PlaceActionsBlock .PlaceActionButton
{
  width: 100%;
}
.PlaceActionsBlock .PlaceRouteButton
{
  margin: 0 6px;
}
