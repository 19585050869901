.MapHeader:not([data-category-id])
{
  grid-template-columns: auto 1fr;
}
.MapHeader > .Icon:first-child
{
  font-size: 15px;
}
.MapHeader .Button[type=back]
{
  font-size: 12px;
}
.MapHeader .Button[type=search]
{
  position: relative;
  font-size: 10px;
  padding: 2px;
}
