.InfoObjectLink
{
  color: #fff;
  width: 100%;
  display: block;
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
}
.InfoObjectLink .Photo
{
  height: calc(100vw * .266);
  min-height: 120px;
  max-height: 200px;
}
.InfoObjectLink .Label
{
  font-size: inherit;
  font-weight: 500;
  line-height: 1.4;
  width: 100%;
  margin: 0;
  padding: 2px 4px;
  text-align: center;
  background: linear-gradient(180deg, transparent, rgb(0 0 0 / .4) 45%);
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  box-sizing: border-box;
}
