.CommentDialog .PopupContent
{
  width: 100%;
  display: grid;
  gap: 20px;
  line-height: 1.4;
  padding: 20px;
  box-sizing: border-box;
}
.CommentDialog .Widget
{
  font-size: inherit;
}
.CommentDialog .TextBox
{
  width: 100%;
  font-size: 12px;
}
.CommentDialog .TextBoxControl
{
  min-height: 100px;
}
.CommentDialog .Button
{
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.CommentDialog .ButtonControl
{
  padding: 8px;
}
