.TripDialog .DialogBody > .Block
{
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
}
.TripDialog .TripMenuButton
{
  font-size: 16px;
  padding: 8px;
}
.TripDialog .LoadingIndicator
{
  position: absolute;
  height: 100%;
  width: 100%;
}
.TripDialog[aria-hidden]
{
  transform: translateY(100%);
}
