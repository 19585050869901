.TripsEmptyBlock
{
  line-height: 1.4;
  padding: 4px;
}
.TripsEmptyBlock .Div
{
  font-size: 80px;
  font-weight: 500;
}
.TripsEmptyBlock .Paragraph
{
  width: 100%;
  margin: 12px 0;
}
.TripsEmptyBlock .Paragraph.bold
{
  font-weight: 500;
}
.TripsEmptyBlock .Icon
{
  font-size: 10px;
  vertical-align: middle;
  margin: 0 4px;
}
.TripsEmptyBlock .Icon_plus_outline_white
{
  background-color: var(--accent-color);
  border-radius: 50%;
  font-size: 6px;
  padding: 4px;
}
