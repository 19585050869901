.TripsFilterGroup
{
  display: block;
}
.TripsFilterGroup > .Inner
{
  display: flex;
  grid-gap: var(--gap);
}
.TripsFilterGroup,
.TripsFilterGroup .Button
{
  font-weight: 500;
}
.TripsFilterGroup .Button > .Control
{
  min-width: auto;
  min-height: 30px;
  color: var(--accent-color);
}
.TripsFilterGroup [aria-checked=true] > .Control
{
  background-color: var(--accent-color);
  color: var(--accent-text-color);
}
.TripsFilterGroup [aria-checked=true] > .Control:active,
.TripsFilterGroup.active [aria-checked=true] > .Control
{
  background-color: var(--accent-active-bg-color);
}
.TripsFilterGroup:focus-visible .Button.focus .Control
{
  border-color: var(--accent-color);
}
