.MobileUserCard
{
  margin: var(--gap) 0;
  padding: 3px 4px;
}
.MobileUserCard .Link
{
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 12px;
  color: inherit;
}
.MobileUserCard .UserCardPhoto
{
  padding: 18px;
  border-radius: 50%;
  background-color: rgb(231 231 231 / .3);
  font-size: 1em;
}
.MobileUserCard .UserCardPhoto .Icon_unsplash_metro_dim
{
  font-size: 10px;
  opacity: .5;
}
