.PlaceCard .PlacePhoto
{
  height: 164px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 30px;
}
.PlaceCard .PlacePhoto small
{
  display: none;
}
.PlaceCard .FootBlock
{
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 5px var(--gap) var(--gap);
}
.PlaceCard .LeftBlock
{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2px;
}
.PlaceCard .PlaceName
{
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.PlaceCard .PlaceTypeName
{
  font-size: 10px;
  font-weight: 300;
}
.PlaceCard .PlaceRating
{
  margin-top: -32px;
  min-width: var(--size);
}
