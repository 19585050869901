.TripActionsMenu
{
  display: flex;
  gap: var(--gap);
}
.TripActionsMenu .MenuItem
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: var(--gap);
  width: 60px;
}
.TripActionsMenu .Icon
{
  background-color: var(--accent-color);
  border-radius: 50%;
  font-size: 12px;
  padding: 7px;
}
