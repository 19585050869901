.TripRouteHead
{
  display: grid;
  grid-template-columns: auto 1fr auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  text-align: center;
  align-items: center;
  padding: 0 12px;
}
.TripRouteHead .Button
{
  font-size: 12px;
  opacity: .15;
  transition: opacity .1s;
}
.TripRouteHead .Button[aria-disabled=true]
{
  opacity: 0;
}
