.ProfilePhotoBlock
{
  position: relative;
  margin-bottom: var(--gap);
}
.ProfilePhotoBlock .ProfilePhoto .Icon,
.ProfilePhotoBlock .ProfilePhotoUploadButton .Icon
{
  font-size: 75px;
}
.ProfilePhotoBlock .ProfilePhoto .Icon
{
  opacity: .3;
}
.ProfilePhotoBlock .ProfilePhotoDeleteButton
{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 12px;
  background-color: rgb(255 255 255 / 50%);
  border-radius: 50%;
  padding: 3px;
}
