.SearchMain
{
  padding: var(--gap);
  min-height: 100%;
  box-sizing: border-box;
}
.SearchMain .LoadingIndicator
{
  height: 100%;
}
.SearchMain .Heading
{
  margin: 4px;
  color: var(--dim-color);
  font-size: inherit;
}
