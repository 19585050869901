.MapScreen
{
  display: grid;
  padding-bottom: 0;
}
.MapScreen .Main
{
  position: relative;
  overflow: hidden;
  display: grid;
}
.MapScreen .MapCategoryMenu
{
  position: absolute;
  width: 100%;
  z-index: 4;
}
.MapScreen .MapBoundsButton
{
  position: absolute;
  z-index: 4;
  top: 50px;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: var(--gap);
}
.MapScreen .MapBackButton
{
  position: absolute;
  z-index: 4;
  top: 50px;
  padding: 12px;
}
.MapScreen .MapApplication
{
  height: 100%;
}
.MapScreen .MapCardBlock
{
  z-index: 40;
}
