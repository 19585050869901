.Card
{
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}
.Card .Link
{
  color: inherit;
}
.Card .Photo
{
  height: 50vw;
  max-height: 50vh;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}
.Card .FootBlock
{
  padding: var(--gap);
}
