.ProfilePhotoUploadButton
{
  font-size: inherit;
  height: 400px;
  display: grid;
}
.ProfilePhotoUploadButton > .ButtonControl
{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  border-radius: 0;
  overflow: hidden;
}
.ProfilePhotoUploadButton .Icon
{
  opacity: .5;
}
.ProfilePhotoUploadButton .ProfilePhoto
{
  height: 100%;
  width: 100%;
}
