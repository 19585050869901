.PlaceInfoBlock
{
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: var(--gap);
  line-height: 1.4;
}
.PlaceInfoBlock > .Icon,
.PlaceInfoBlock > .Button
{
  font-size: 8px;
  margin: 2px 0;
}
.PlaceInfoBlock > .Inner
{
  margin-right: var(--gap);
}
.PlaceInfoBlock .Icon_expand-arrow_custom_black
{
  padding: 0 16px;
}
.PlaceInfoBlock.open .Icon_expand-arrow_custom_black
{
  transform: rotate(180deg);
}
