.TripDateTab
{
  display: flex;
  font-size: 20px;
  font-weight: 600;
}
.TripDateTab .Time
{
  display: block;
  line-height: 19px;
}
.TripDateTab > .Control
{
  min-width: var(--tab-width);
  border: none;
  box-sizing: border-box;
  text-align: center;
  transition: transform .2s;
}
.TripDateTab[aria-selected=true] .Time
{
  font-weight: 500;
}
