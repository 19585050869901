.SlidePopup
{
  padding: 0;
  align-items: flex-end;
}
.SlidePopup > .PopupContent
{
  width: 100%;
  max-width: var(--max-width);
  border-radius: 10px 10px 0 0;
  padding: 8px 20px;
}
.SlidePopup > .PopupContent::before
{
  --width: 20px;

  content: '';
  position: absolute;
  width: var(--width);
  height: 3px;
  background-color: #e7e7e7;
  border-radius: 3px;
  top: 8px;
  left: 50%;
  margin-left: calc(0px - var(--width) / 2);
}
.SlidePopup .PopupMenu
{
  padding: 10px 0;
}
.SlidePopup[aria-hidden]
{
  transform: translateY(100%);
}
