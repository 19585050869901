.MapObjectCard
{
  background-color: var(--bg-color);
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr auto;
  box-sizing: border-box;
  gap: 20px;
}
.MapObjectCard .Time
{
  font-size: 10px;
}
.MapObjectCard .Button.round
{
  font-size: 10px;
  margin-right: 8px;
}
.MapObjectCard .UserPhoto
{
  --user-photo-size: 30px;
}
