.MapPlaceCard
{
  background-color: var(--bg-color);
  padding: 12px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: var(--gap);
  font-weight: 300;
  min-height: 170px;
}
.MapPlaceCard .MainBlock
{
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: var(--gap);
}
.MapPlaceCard .LeftBlock
{
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
}
.MapPlaceCard .RightBlock
{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
.MapPlaceCard .Heading
{
  font-size: inherit;
  font-weight: 500;
}
.MapPlaceCard .PlaceFeaturesBlock
{
  margin-top: 4px;
}
