.TicketsScreen .MainHead .Icon
{
  font-size: 12px;
}
.TicketsScreen .Main
{
  min-height: 100%;
  padding: 12px;
}
.TicketsScreen .Heading
{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.TicketsScreen .TabList
{
  display: flex;
  gap: 5px;
  margin: 16px 0;
}
.TicketsScreen .TabList .Tab
{
  color: var(--accent-color);
  padding: var(--control-padding);
  border-radius: var(--border-radius);
  background: var(--control-bg-color);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
  font-weight: 500;
}
.TicketsScreen .TabList .Tab[aria-selected=true]
{
  background-color: var(--accent-color);
  color: var(--accent-text-color);
}
.TicketsScreen .TextBox
{
  display: block;
  width: 100%;
  margin: 14px 0;
}
.TicketsScreen .Group > .Label
{
  font-size: 10px;
}
.TicketsScreen .SpinButton
{
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  align-items: center;
  gap: 12px;
  margin: var(--gap) 0;
}
.TicketsScreen .SpinButton .Label
{
  font-size: 12px;
}
.TicketsScreen .SpinButton .ButtonControl
{
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
  border: none;
  padding: 0 16px;
  min-width: auto;
}
.TicketsScreen .SpinButton .ButtonLabel
{
  font-size: 26px;
  color: var(--accent-color);
}
.TicketsScreen .SpinButton .Span
{
  font-size: 20px;
  display: block;
  width: 60px;
  text-align: center;
}
.TicketsScreen .RadioGroup
{
  margin: 16px 0;
}
.TicketsScreen .RadioGroup > .Inner
{
  display: flex;
  gap: 16px;
  justify-content: space-between;
}
.TicketsScreen .RadioGroup .ButtonControl
{
  border: none;
  padding: 7px 18px;
  font-weight: 500;
}
.TicketsScreen .RadioGroup .Button:not(:first-child) .ButtonControl
{
  color: #fff;
}
.TicketsScreen .Button[type=submit]
{
  display: block;
  margin: 20px auto;
  font-weight: 500;
}
.TicketsScreen .Button[type=submit] .ButtonControl
{
  padding: 7px 18px;
}
