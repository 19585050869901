.PlaceLocationBlock > .Inner
{
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  position: relative;
}
.PlaceLocationBlock .Group
{
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: -2px;
  display: flex;
  grid-gap: 12px;
}
.PlaceLocationBlock .Group .Button
{
  font-size: 12px;
}
.PlaceLocationBlock address
{
  font-style: normal;
}
.PlaceLocationBlock[aria-busy=true] address
{
  color: var(--dim-color);
}
