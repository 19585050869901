.ProfileItem
{
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 8px 0;
  grid-column-gap: 6px;
  line-height: 20px;
}
.ProfileItem .Icon
{
  font-size: 12px;
  align-self: flex-start;
}
.ProfileItem .Icon + *
{
  margin: 1px 0 4px;
}
.ProfileItem a[href]
{
  color: inherit;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.ProfileItem .Switch
{
  display: flex;
}
.ProfileItem .Switch[aria-hidden=true]
{
  display: none;
}
.ProfileItem .Button
{
  font-size: inherit;
  line-height: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: normal;
  overflow: hidden;
}
.ProfileItem .Button[aria-hidden=true]
{
  display: none;
}
.ProfileItem.blank
{
  color: var(--dim-color);
}
