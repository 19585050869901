.InfoLocalityPeoplePanel
{
  --user-photo-size: 60px;
}
.InfoLocalityPeoplePanel .Group
{
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--user-photo-size));
  justify-content: space-between;
  gap: 12px 16px;
}
.InfoLocalityPeoplePanel .Group .Link
{
  display: block;
}
