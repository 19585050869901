.MapCategoryItem
{
  padding: 2px 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}
.MapCategoryItem .Icon
{
  font-size: 15px;
}
.MapCategoryItem .Label
{
  margin: 0;
  font-size: 8px;
  text-align: center;
}
