.SettingsAccessDialog .RadioGroup
{
  display: block;
}
.SettingsAccessDialog .RadioGroup > .Inner,
.SettingsAccessDialog .Group
{
  padding-left: 8px;
}
.SettingsAccessDialog .Block > .Label
{
  font-size: 10px;
  color: inherit;
  margin-bottom: 4px;
}
.SettingsAccessDialog .Radio,
.SettingsAccessDialog .CheckBox
{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  text-align: left;
}
.SettingsAccessDialog .RadioLabel,
.SettingsAccessDialog .CheckBoxLabel
{
  margin: 0;
}
.SettingsAccessDialog .RadioControl,
.SettingsAccessDialog .CheckBoxControl
{
  padding: 9px;
  background-color: var(--control-bg-color);
  border: var(--control-border);
  border-color: var(--accent-color);
  border-radius: 50%;
}
.SettingsAccessDialog .Radio[aria-checked=true] > .Control,
.SettingsAccessDialog .CheckBox[aria-checked=true] > .Control
{
  background-color: var(--checked-bg-color);
}
.SettingsAccessDialog .RadioControl::before,
.SettingsAccessDialog .CheckBoxControl::before
{
  display: none;
}
.SettingsAccessDialog .Widget[aria-disabled=true],
.SettingsAccessDialog .Widget[aria-disabled=true] > .Label,
.SettingsAccessDialog .RadioGroup[aria-disabled=true] .Radio
{
  color: inherit;
}
