.TripForm
{
  --padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
}
.TripForm .NomadLogo
{
  --logo-width: 260px;
}
.TripForm .SuggestBox,
.TripForm .TripDateBox
{
  width: 100%;
  max-width: none;
  font-size: inherit;
}
.TripForm .SuggestBox > .Label,
.TripForm .ComboBox > .Label,
.TripForm .Group > .Label
{
  font-size: 10px;
  color: #fff;
  margin: 0 0 4px;
}
.TripForm .TextBox > .Control,
.TripForm .ComboBox > .Control
{
  padding: 10px 8px;
  border-color: transparent;
}
.TripForm .PlaceCategoryGroup > .Label
{
  margin-bottom: 0;
}
.TripForm .MainBlock
{
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  grid-gap: calc(2 * var(--gap));
  width: 100%;
  box-sizing: border-box;
}
.TripForm .PlaceCategoryGroup
{
  width: 100%;
  padding-top: 6px;
}
.TripForm .FootBlock
{
  width: 100%;
  padding: var(--padding);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.TripForm .FootBlock .Button
{
  width: 100%;
}
.TripForm .FootBlock .ButtonLabel
{
  font-weight: 500;
}
.TripForm .FootBlock .ButtonControl
{
  padding: 10px 16px;
}
.TripForm .Button[type=submit] .ButtonControl
{
  border-color: var(--accent-color);
}
.TripForm .Button[type=submit][aria-disabled=true] .ButtonControl
{
  border-color: transparent;
}
.TripForm .ValidationAlert
{
  margin-top: -12px;
}
/*@media (max-height: 570px)
{
  .TripForm
  {
    align-items: flex-start;
  }
}*/
