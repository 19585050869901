:root
{
  --user-photo-size: 50px;
}
.UserPhoto
{
  position: relative;
  background: #e7e7e7 center center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: var(--user-photo-size);
  height: var(--user-photo-size);
}
.UserPhoto .Icon
{
  position: absolute;
  opacity: .5;
}
