.PlaceCategoryDialog > .DialogContent
{
  background: #fff url(media/landscape_1200.webp) center center / cover no-repeat;
}
.PlaceCategoryDialog > .DialogContent::before
{
  content: '';
  background-color: rgb(0 0 0 / .3);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.PlaceCategoryDialog .LoadingIndicator
{
  position: absolute;
  width: 100%;
  height: 100%;
}
.PlaceCategoryDialog .DialogHead
{
  display: flex;
  justify-content: space-between;
}
.PlaceCategoryDialog .DialogBody
{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.PlaceCategoryDialog .PlaceCategoryGroup
{
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.PlaceCategoryDialog .PlaceCategoryGroup > .HeadBlock
{
  padding: 0 16px;
}
.PlaceCategoryDialog .PlaceCategoryGroup > .Control
{
  background-color: transparent;
  border: none;
  padding: var(--gap) 10px;
  height: 100%;
}
.PlaceCategoryDialog .PlaceCategoryBox
{
  display: flex;
  align-items: center;
}
.PlaceCategoryDialog .PlaceCategoryBox > .Control
{
  border: none;
  width: 25vw;
  min-width: 80px;
  max-width: 180px;
  height: 12vh;
  min-height: 70px;
  max-height: 150px;
}
.PlaceCategoryDialog .Button[type=submit]
{
  width: 100%;
}
.PlaceCategoryDialog .Button[type=submit] > .Control
{
  padding: 10px;
  font-weight: 500;
}
.PlaceCategoryDialog[aria-hidden]
{
  transform: translateX(100%);
}
