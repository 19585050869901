.TripPhoto
{
  position: relative;
  background: rgba(215, 221, 255, 0.15) center center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}
.TripPhoto .Icon
{
  font-size: 25px;
  position: absolute;
  opacity: .5;
}
