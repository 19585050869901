.MapTripBlock
{
  --button-border-color: transparent;

  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  overflow: auto;
}
.MapTripBlock .Button
{
  font-size: 12px;
  font-weight: 500;
}
.MapTripBlock .ButtonControl
{
  color: var(--dim-color);
  padding: 6px 8px;
  min-width: auto;
}
.MapTripBlock .Button[aria-pressed=true]
{
  opacity: 1;
}
.MapTripBlock .Button[aria-pressed=true] .ButtonControl
{
  background-color: var(--bg-color);
  color: var(--text-color);
  border-color: var(--button-border-color);
}
.MapTripBlock .Button .Icon
{
  font-size: 10px;
}
