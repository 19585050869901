.ProfileDetailsDialog .TextBoxControl
{
  padding-bottom: 20px;
}
.ProfileDetailsDialog .Status
{
  font-size: 8px;
  text-align: right;
  margin: -12px 5px 0;
  position: relative;
  z-index: 1;
}
.ProfileDetailsDialog .Status.overflow
{
  color: var(--attention-color);
}
