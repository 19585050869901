.MapApplication > div,
.MapApplication .LoadingIndicator
{
  height: 100%;
}
.MapApplication div.MapPlaceMarker
{
  pointer-events: auto;
}
.MapApplication .gm-style iframe + div
{
  border: none !important;
}
