.TripPlaceItem
{
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  position: relative;
  gap: var(--gap);
  transition: transform .2s;
  margin-top: var(--gap);
}
.TripPlaceItem[aria-grabbed]
{
  cursor: grab;
}
.TripPlaceItem[aria-grabbed]:active
{
  cursor: grabbing;
}
.TripPlaceItem[aria-grabbed=true]
{
  transition: none;
  z-index: 1;
}
.TripPlaceItem > .Block
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  overflow: hidden;
}
.TripPlaceItem .Button[type=menu]
{
  font-size: 12px;
}
.TripPlaceItem .Icon_message_filled
{
  font-size: 10px;
}
