.TripDateDialog
{
  --inner-max-width: 280px;

  padding: 0;
}
.TripDateDialog .Form
{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 12px;
  line-height: 1.3;
  align-self: flex-end;
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.TripDateDialog .DatePicker
{
  width: 100%;
  max-width: var(--inner-max-width);
}
.TripDateDialog .DatePickerControl
{
  border: none;
  padding: 0;
}
.TripDateDialog .MonthBox,
.TripDateDialog .YearBox
{
  font-size: 14px;
}
.TripDateDialog .DatePickerHead
{
  justify-content: center;
  padding: 0 0 14px;
}
.TripDateDialog .MonthYearBoxButton,
.TripDateDialog .DatePickerButton[type=today]
{
  display: none;
}
.TripDateDialog .DatePickerGrid
{
  box-shadow: var(--card-box-shadow);
  border-radius: var(--control-border-radius);
  padding: 2px 18px var(--gap);
}
.TripDateDialog .DatePicker:focus-visible .DatePickerGrid
{
  border-color: var(--accent-color);
}
.TripDateDialog .Time
{
  font-size: 12px;
}
.TripDateDialog .Button[type=submit]
{
  width: 100%;
  max-width: var(--inner-max-width);
}
.TripDateDialog .Button[type=submit] > .Control
{
  font-size: 14px;
  border-color: var(--accent-color);
  padding: var(--gap);
}
.TripDateDialog .Button[type=submit] .Label
{
  font-weight: 400;
}
.TripDateDialog .Button[type=submit][aria-disabled=true] > .Control
{
  border-color: transparent;
}
.TripDateDialog[aria-busy=true] .Time
{
  color: var(--dim-color);
}
.TripDateDialog[aria-busy=true] .DatePickerGrid
{
  border-color: transparent;
}
.TripDateDialog[aria-hidden]
{
  transform: translateY(100%);
}
