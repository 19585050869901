.InfoLocalityAboutPanel
{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.InfoLocalityAboutPanel .Status
{
  margin: 14px 0;
}
