.ProfileName
{
  font-size: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.ProfileName .OnlineIndicator
{
  padding: 4px;
}
.ProfileName > .Inner
{
  padding: 5px 0;
}
.ProfileName .Button
{
  padding: 5px;
  font-size: 12px;
}
.ProfileName .Button[type=favorite]
{
  font-size: 14px;
}
