.TripDotList
{
  position: absolute;
  width: 100%;
  display: flex;
  gap: 3px;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
}
.TripDotList .Tab
{
  width: 5px;
  height: 5px;
  background-color: #e7e7e7;
  border-radius: 3px;
}
.TripDotList .Tab[aria-selected=true]
{
  width: 30px;
}
