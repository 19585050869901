.PlaceDetailsCard
{
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.PlaceDetailsCard .MainBlock
{
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.PlaceDetailsCard .MainBlock > .Group
{
  display: grid;
  grid-template-columns: 1fr auto;
}
.PlaceDetailsCard .Heading
{
  display: block;
}
.PlaceDetailsCard .PlaceFeaturesBlock
{
  font-size: 10px;
  margin: 20px 0;
}
.PlaceDetailsCard .PlaceActionsBlock
{
  padding: 12px;
}
