.SettingsAccountDialog .DialogBody
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.SettingsAccountDialog .FootBlock
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 40px 4px;
}
.SettingsAccountDialog .FootBlock .Button
{
  font-size: inherit;
  font-weight: 500;
  width: 100%;
}
.SettingsAccountDialog .FootBlock .ButtonControl
{
  padding: 8px;
}
