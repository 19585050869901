.TravelModeDialog > .PopupContent
{
  padding: var(--gap);
}
.TravelModeDialog .TravelModeListBox
{
  padding: 16px 12px 0;
  width: 100%;
}
.TravelModeDialog .ListBoxLabel
{
  font-size: inherit;
}

.TravelModeDialog .ListBoxControl
{
  padding: 12px 0;
  gap: 16px;
  display: flex;
  justify-content: space-around;
}
