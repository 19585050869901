.FilterDialog .Slider
{
  padding-right: 18px;
}
.FilterDialog .TripDateBox,
.FilterDialog .TravelModeListBox
{
  display: block;
}
.FilterDialog .DialogBody > .Widget
{
  margin: 20px 0;
}
.FilterDialog .DialogFoot
{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 12px;
}
.FilterDialog .DialogFoot .Button
{
  font-size: inherit;
  width: 100%;
}
.FilterDialog .DialogFoot .ButtonControl
{
  padding: var(--gap) calc(2 * var(--gap));
}
.FilterDialog .Button[type=submit] > .Control
{
  border-color: var(--accent-color);
}
.FilterDialog[aria-hidden]
{
  transform: translateY(100%);
}
