.InfoLocalitiesFeed,
.InfoLocalitiesFeed .LocalityCard
{
  margin-top: 16px;
}
.InfoLocalitiesFeed .LoadingIndicator
{
  height: 200px;
}
.InfoLocalitiesFeed .LocalityCoords
{
  display: none;
}
.InfoLocalitiesFeed .LocalityCard
{
  transition: opacity .2s;
}
