.MapCategoryMenu
{
  display: flex;
  justify-content: space-between;
  background-color: rgb(255 255 255 / .9);
  overflow-x: scroll;
  padding: 2px 0;
}
.MapCategoryMenu::-webkit-scrollbar
{
  display: none;
}
.MapCategoryMenu[aria-hidden=true]
{
  display: none;
}
