.MapPlaceMarker
{
  --size: 30px;

  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width .1s, height .1s;
  transform-origin: bottom;
  cursor: default;
}
.MapPlaceMarker svg
{
  width: 100%;
  height: 100%;
}
.MapPlaceMarker path
{
  transition: stroke .1s;
}
.MapPlaceMarker .Icon
{
  font-size: 8px;
  position: absolute;
  margin-top: -16%;
  transition: font-size .1s;
}
.MapPlaceMarker.focus
{
  --size: 40px;
}
.MapPlaceMarker.focus .Icon
{
  font-size: 11px;
}
