.InfoPlacesBlock .Status
{
  min-height: 200px;
}
.InfoPlacesBlock .Group
{
  align-items: flex-start;
  scroll-snap-type: x mandatory;
}
.InfoPlacesBlock .PlaceCard
{
  min-width: 100%;
  scroll-snap-align: center;
}
.InfoPlacesBlock.compact .PlaceCard
{
  min-width: 248px;
  scroll-snap-align: none;
}
