.InfoPlacesMain
{
  display: grid;
  grid-template-rows: auto 1fr;
}
.InfoPlacesMain .Search
{
  padding: 12px;
  overflow: hidden;
}
.InfoPlacesMain .SearchBox
{
  display: block;
  width: auto;
}
