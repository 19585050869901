.PlaceOption
{
  line-height: 1.5;
}
.PlaceOption mark
{
  background-color: rgb(255 202 0 / .2);
}
.PlaceOption > div
{
  overflow: hidden;
  text-overflow: ellipsis;
}
.PlaceOption .Note
{
  font-size: 8px;
}
