.TripPlaceInfoDialog .PopupContent
{
  display: block;
  overflow: auto;
}
.TripPlaceInfoDialog .HeadBackButton
{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  font-size: 12px;
  padding: 8px;
  margin: 0 4px;
}
.TripPlaceInfoDialog .Article
{
  padding: 4px 12px;
}
.TripPlaceInfoDialog .FootBlock
{
  margin: 24px 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: var(--gap);
  white-space: nowrap;
  color: var(--dim-color);
}
.TripPlaceInfoDialog .FootBlock .ButtonControl
{
  padding: 8px 20px;
}
