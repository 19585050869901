.MapLocationButton
{
  margin-right: var(--gap);
  margin-bottom: 240px;
}
.MapLocationButton > .Control
{
  min-width: auto;
  font-size: 15px;
  padding: 5px;
}
.MapLocationButton[aria-pressed] > .Control
{
  background-color: var(--bg-color);
}
.MapLocationButton[aria-pressed] > .Control:active,
.MapLocationButton[aria-pressed].active > .Control
{
  background-color: var(--active-bg-color);
}
.MapLocationButton[aria-busy=true] .Icon
{
  animation: MapLocationButton 2s linear infinite;
}
@keyframes MapLocationButton
{
  from
  {
    transform: rotate(0);
  }
  to
  {
    transform: rotate(360deg);
  }
}
