.ProfileTagItem .List
{
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  margin-top: var(--gap);
}
.ProfileTagItem .ListItem
{
  font-size: 10px;
  line-height: 14px;
  padding: 1px 5px;
  border: var(--control-border);
  border-radius: var(--border-radius);
}
