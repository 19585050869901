:root
{
  --header-size: 44px;
  --menu-size: 64px;
}
:root
{
  --popup-z-index: 10;
  --header-z-index: 20;
  --menu-z-index: 30;
  --tripform-z-index: 40;
  --combobox-z-index: 50;
  --popup-modal-z-index: 100;
}
html
{
  --doc-height: 100%;
  min-height: var(--doc-height);
}
.MobileApp
{
  min-height: 100vh;
  margin: 0 auto;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%);
}
.MobileApp .MobileHeader,
.MobileApp .MobileMenu
{
  position: fixed;
  width: 100%;
  max-width: var(--max-width);
}
.MobileApp .MobileHeader
{
  top: 0;
  height: var(--header-size);
  z-index: var(--header-z-index);
}
.MobileApp .MobileMenu
{
  bottom: 0;
  height: var(--menu-size);
  z-index: var(--menu-z-index);
}
.SuggestBoxPopup
{
  z-index: var(--combobox-z-index);
}
