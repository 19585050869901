.AuthScreen
{
  --header-size: 0;
  --menu-size: 0;
  --width: 260px;

  padding: 70px 12px 30px;
  color: var(--accent-color);
  background-color: var(--bg-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}
.AuthScreen .LoadingIndicator
{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.AuthScreen .NomadLogo
{
  --logo-width: 220px;
}
.AuthScreen > .Content
{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}
.AuthScreen > .Content > .Block
{
  width: var(--width);
}
.AuthScreen .Button
{
  width: 100%;
}
.AuthScreen .ButtonControl,
.AuthScreen .Button[type=submit] > .Control,
.AuthScreen .TextBoxControl
{
  font-size: 18px;
  line-height: 34px;
  padding: 1px var(--gap);
}
.AuthScreen .Button[type=back]
{
  position: absolute;
  left: 0;
  top: 0;
  padding: 16px;
  width: auto;
  font-size: 12px;
}
.AuthScreen .Button.flying > .Control
{
  color: inherit;
}
.AuthScreen .ButtonLabel
{
  font-weight: 500;
}
.AuthScreen .EmailAuthForm
{
  width: var(--width);
}
.AuthScreen .TextBoxControl
{
  font-size: 16px;
}
.AuthScreen .CheckBoxControl
{
  font-size: 30px;
}
.AuthScreen .AuthSocialBlock
{
  margin-top: 74px;
}
.AuthScreen small.LegalConfirmationBlock
{
  max-width: var(--width);
  text-align: center;
  font-size: 14px;
}
