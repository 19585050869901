.EmailAuthForm
{
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 16px;
  font-size: 10px;
}
.EmailAuthForm .Widget
{
  width: 100%;
  font-size: inherit;
}
.EmailAuthForm .TextBoxLabel
{
  margin-bottom: 4px;
  color: var(--text-color);
}
.EmailAuthForm .TextBoxControl
{
  font-size: 14px;
  padding: 7px 8px;
}
.EmailAuthForm .CheckBox
{
  white-space: normal;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  grid-gap: 14px;
}
.EmailAuthForm .CheckBoxControl
{
  font-size: 24px;
}
.EmailAuthForm .CheckBoxControl::before
{
  font-size: 22px;
}
.EmailAuthForm .CheckBoxLabel
{
  margin: 0;
  font-size: 12px;
}
.EmailAuthForm .CheckBox a[href]:focus-visible
{
  outline: none;
  background-color: var(--focus-color);
  color: #fff;
}
.EmailAuthForm .CheckBox[aria-disabled=true] a[href],
.EmailAuthForm .CheckBox[aria-invalid=true] a[href]
{
  color: inherit;
}
.EmailAuthForm .CheckBox[aria-checked=true] > .CheckBoxControl
{
  background-color: var(--bg-color);
  border-color: var(--accent-color);
}
.EmailAuthForm .CheckBox[aria-checked=true] > .CheckBoxControl::before
{
  color: var(--accent-color);
}
.EmailAuthForm .CheckBox[aria-hidden=true]
{
  display: none;
}
.EmailAuthForm .Button[type=submit]
{
  display: block;
  margin-top: 24px;
}
.EmailAuthForm .Button[type=submit] > .Control
{
  font-size: 16px;
  padding: 12px;
}
.EmailAuthForm .Button[type=submit] .Label
{
  font-weight: 400;
}
