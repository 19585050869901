.InfoBlock
{
  --padding: 8px 12px;

  overflow: hidden;
}
.InfoBlock .Heading
{
  font-size: inherit;
  padding: var(--padding);
}
.InfoBlock .Heading .Link
{
  display: flex;
  justify-content: space-between;
  color: inherit;
}
.InfoBlock .Heading .Span
{
  color: var(--link-color);
  font-weight: 300;
}
.InfoBlock .Status
{
  padding: var(--padding);
}
.InfoBlock .Group
{
  display: flex;
  gap: var(--gap);
  overflow-x: auto;
  padding: var(--padding);
}
.InfoBlock .Group::-webkit-scrollbar
{
  display: none;
}
