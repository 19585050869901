.InfoLocalitiesBlock .Status
{
  min-height: 150px;
}
.InfoLocalitiesBlock .Photo,
.InfoLocalitiesBlock .PlacePhoto
{
  height: 110px;
}
.InfoLocalitiesBlock .LocalityCard
{
  min-width: 248px;
}
.InfoLocalitiesBlock .LocalityCoords
{
  display: none;
}
