.PlaceHoursBlock
{
  display: flex;
  align-items: center;
  grid-gap: var(--gap);
}
.PlaceHoursBlock .Icon
{
  font-size: 8px;
}
