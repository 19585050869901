.InfoLocalityPlacesPanel
{
  overflow-x: hidden;
}
.InfoLocalityPlacesPanel .InfoPlacesBlock
{
  --padding: 8px 12px;
  margin: 12px 0;
}
.InfoLocalityPlacesPanel .InfoPlacesBlock .Group
{
  gap: 4px;
}
