.PlaceRating
{
  --size: 45px;

  font-size: 16px;
  padding: 0;
  width: var(--size);
  height: var(--size);
  flex-direction: column;
  line-height: 1.3;
  border: none;
}
.PlaceRating > .Inner
{
  position: static;
}
.PlaceRating .RatingTotalCount
{
  font-size: 10px;
}
