.TripDateBox > .Control
{
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 5px 6px;
}
.TripDateBox.blank > .Control
{
  color: var(--dim-color);
}
