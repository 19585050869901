.TripDateList
{
  --tab-gap: 20px;
  --tab-width: calc(50% - 60px);

  display: flex;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  background-color: var(--bg-color);
  z-index: 1;
  max-width: 100%;
  overflow: hidden;
}
.TripDateList::-webkit-scrollbar
{
  display: none;
}
.TripDateList .Tab
{
  position: relative;
  width: 100vw;
}
.TripDateList[aria-busy=true] .Tab > .Control
{
  transition: none;
}
