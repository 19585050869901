.ProfileHeader > .Div
{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ProfileHeader:has(.Button[type=back])::after
{
  content: '';
  padding: 12px;
}
.ProfileHeader:not(:has(.Heading))
{
  display: flex;
  justify-content: space-between;
}
.ProfileHeader:not(:has(.Heading)) .Button[type=search]
{
  position: relative;
}
