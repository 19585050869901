.PlaceCategoryBox
{
  padding: 0;
  justify-self: center;
  min-width: 52px;
  font-size: 13px;
  white-space: normal;
  text-align: center;
  line-height: 1.1;
}
.PlaceCategoryBox > .Control
{
  padding: 5px;
  width: 80px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.PlaceCategoryBox .Label
{
  margin: 0;
  font-size: 10px;
  padding: 0 4px;
  min-height: 22px;
  display: flex;
  align-items: center;
}
.PlaceCategoryBox[aria-checked=true] > .Control
{
  background-color: var(--action-color);
}
.PlaceCategoryBox[aria-disabled=true] > .Control,
.PlaceCategoryBox[aria-disabled=true]:hover > .Control
{
  background-color: #e7e7e7;
}
.PlaceCategoryBox[aria-disabled=true] .Icon
{
  opacity: .7;
}
.PlaceCategoryBox[aria-disabled=true] .Label
{
  color: #bababa;
}
