.TripRoutesList
{
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.TripRoutesList[aria-busy=true] .TripRouteItem
{
  transition: none;
}
