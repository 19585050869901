.TripFormDialog > .DialogContent
{
  background: #fff url(media/landscape_1200.webp) center center / cover no-repeat;
}
.TripFormDialog > .DialogContent::before
{
  content: '';
  background-color: rgb(0 0 0 / .3);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.TripFormDialog .Heading
{
  color: #fff;
}
.TripFormDialog .DialogHead
{
  display: flex;
  justify-content: space-between;
}
.TripFormDialog .DialogBody
{
  padding: 0;
  border: 0;
}
.TripFormDialog.initial
{
  z-index: var(--tripform-z-index);
  bottom: var(--menu-size);
}
.TripFormDialog.initial .DialogContent
{
  grid-template-rows: 1fr;
}
.TripFormDialog.initial .DialogHead,
.TripFormDialog:not(.initial) .NomadLogo,
.TripFormDialog:not(.initial) .Button[type=cancel]
{
  display: none;
}
.TripFormDialog[aria-hidden=false].initial
{
  transform: none;
  opacity: 1;
}
