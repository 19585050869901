.TripsScreen
{
  display: grid;
}
.TripsScreen.welcome
{
  --header-size: 0;
}
.TripsScreen.welcome .MobileHeader
{
  display: none;
}
.TripsScreen .Main
{
  padding: 12px;
}
.TripsScreen .Main > .HeadBlock
{
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(2 * var(--gap));
}
