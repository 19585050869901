.PlaceActionButton
{
  border-radius: var(--border-radius);
  font-size: 12px;
  cursor: default;
}
.PlaceActionButton > .Control
{
  border-color: var(--accent-color);
  min-width: auto;
  padding: 3px 8px;
}
/*.PlaceActionButton[aria-disabled=true] .Icon
{
  opacity: .3;
}*/
