.AuthSocialBlock
{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.AuthSocialBlock .ButtonControl
{
  flex-direction: row-reverse;
  padding: 1px;
  gap: 25px;
}
.AuthSocialBlock .Button .Icon
{
  font-size: 14px;
}
.AuthSocialBlock .Button:focus-visible > .ButtonControl
{
  background-color: var(--action-color);
}
