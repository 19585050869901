.ModalDialog
{
  padding: 0;
}
.ModalDialog::before
{
  display: none;
}
.ModalDialog > .PopupContent
{
  border-radius: 0;
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  box-shadow: none;
}
.ModalDialog > .PopupContent > .LoadingIndicator
{
  font-size: 40px;
}
.ModalDialog .DialogHead
{
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 6px 8px;
}
.ModalDialog .DialogHead .Button
{
  font-size: 12px;
  line-height: 1;
  padding: 4px;
  border-radius: 50%;
}
.ModalDialog .DialogHead .Button:focus-visible
{
  background-color: rgb(0 0 0 / .05);
}
.ModalDialog .DialogHead .Button[aria-disabled=true]
{
  opacity: .1;
}
.ModalDialog .DialogHead .Heading
{
  margin: 0;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ModalDialog .DialogHead .Heading .Span
{
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ModalDialog .DialogHead:has(.Heading:last-child)::after
{
  content: '';
  padding: 16px;
}
.ModalDialog .DialogBody
{
  box-sizing: border-box;
  padding: 12px;
}
