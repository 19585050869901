.InfoLocalitiesMain
{
  display: grid;
  grid-template-rows: auto 1fr;
}
.InfoLocalitiesMain .Search
{
  padding: 12px;
}
.InfoLocalitiesMain .SearchBox
{
  display: block;
  width: auto;
}
