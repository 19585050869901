.MainHead
{
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: var(--gap);
  align-items: center;
  padding: 6px 8px;
}
.MainHead .Button,
.MainHead .Link
{
  font-size: 12px;
  line-height: 1;
  padding: 4px;
}
.MainHead .Heading
{
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.MainHead:has(.Heading:last-child)::after
{
  content: '';
  padding: 16px;
}
