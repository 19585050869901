.TripPlaceList
{
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  padding-left: var(--gap);
}
.TripPlaceList .TripPlaceItem,
.TripPlaceList .TripDurationItem,
.TripPlaceList .TripServicesItem
{
  margin-left: var(--item-margin-left);
}
.TripPlaceList .TripDurationItem
{
  padding-left: 11px;
  margin-bottom: 5px;
}
.TripPlaceList .TripServicesItem
{
  margin-bottom: 5px;
}
.TripPlaceList .TripPlaceItem::before
{
  content: '';
  position: absolute;
  top: calc(50% - 10px);
  left: calc(-10px - var(--item-margin-left));
  width: 11px;
  height: 11px;
  border: 5px solid #fff;
  border-radius: 50%;
  background-color: var(--route-color);
}
.TripPlaceList::before
{
  content: '';
  position: absolute;
  top: 21px;
  bottom: 12px;
  left: var(--gap);
  width: 1px;
  background-color: var(--route-color);
}
.TripPlaceList .TripPlaceButton > .Control
{
  min-height: var(--item-height);
}
