.UserStatusDialog > .PopupContent
{
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.UserStatusDialog .TextBox
{
  width: 100%;
  font-size: 12px;
}
.UserStatusDialog .Button[type=submit]
{
  margin-top: 10px;
}
.UserStatusDialog .Button[type=submit] .ButtonControl
{
  padding: 8px;
}
