.ProfilePhotoDialog .DialogBody
{
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 20px;
}
.ProfilePhotoDialog .DialogBody .Button
{
  display: block;
  font-size: inherit;
  font-weight: 500;
  width: 100%;
}
.ProfilePhotoDialog .DialogBody .ButtonControl
{
  padding: 6px;
}
.ProfilePhotoDialog .DialogBody .Button[aria-hidden=true]
{
  display: none;
}
.ProfilePhotoDialog .Img
{
  width: 100%;
  height: 400px;
  display: block;
}
.ProfilePhotoDialog[aria-busy=true] .DialogBody > .Block
{
  display: flex;
  justify-content: center;
  align-items: center;
}
