.TripRouteButton
{
  display: block;
}
.TripRouteButton > .Control
{
  border: none;
  padding: var(--gap);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 15px;
}
.TripRouteButton .Label
{
  font-weight: 500;
}
