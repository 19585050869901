.InfoCountryLink
{
  color: inherit;
  display: flex;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-weight: 500;
  gap: 12px;
  padding: 0;
}
.InfoCountryLink .CountryFlag
{
  font-size: 9px;
  width: 100%;
  height: calc(100vw * .266);
  min-height: 100px;
  max-height: 175px;
}
.InfoCountryLink .Span
{
  display: flex;
  align-items: center;
}
