.UserCardPhoto
{
  position: relative;
  background: rgba(215, 221, 255, 0.15) center center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 2em;
}
.UserCardPhoto .Icon
{
  position: absolute;
}
