.MapPlaceDialog
{
  --block-padding-h: 12px;
}
.MapPlaceDialog > .DialogContent
{
  grid-template-rows: 1fr;
}
.MapPlaceDialog .HeadBackButton
{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  padding: 10px;
}
