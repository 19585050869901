.NomadLogo
{
  --logo-width: 470px;

  display: block;
  width: var(--logo-width);
  height: calc(var(--logo-width) * .17);
  background: url(NomadLogo.png) center center / contain no-repeat;
}
.NomadLogo.white
{
  background-image: url(NomadLogo_white.png);
}
