.ProfileCountryDialog .ListItem
{
  grid-template-columns: auto 1fr auto;
  grid-gap: var(--gap);
}
.ProfileCountryDialog .Img
{
  width: 30px;
  height: 20px;
}
