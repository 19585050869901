.TripDurationItem .Button
{
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px;
}
.TripDurationItem.dim .Button
{
  color: var(--dim-color);
}
.TripDurationItem .Icon
{
  padding: 2px;
  font-size: 8px;
}
