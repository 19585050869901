.LoadingIndicator
{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 50px;
  box-sizing: border-box;
}
.LoadingIndicator::before,
.LoadingIndicator::after
{
  content: '';
  padding: 1em;
}
.LoadingIndicator::before
{
  border-radius: 50%;
  border-width: .1em;
  border-style: solid;
  border-color: var(--action-color) var(--action-color) var(--accent-color) var(--accent-color);
  animation: LoadingIndicator-before .6s linear infinite;
}
.LoadingIndicator::after
{
  background: url('icons/planirus_custom.png') center center / 50% no-repeat;
  animation: LoadingIndicator-after 2.4s linear infinite;
  position: absolute;
}
@keyframes LoadingIndicator-before
{
  0%
  {
    transform: rotate(0);
  }
  50%
  {
    transform: rotate(180deg);
  }
  100%
  {
    transform: rotate(360deg);
  }
}
@keyframes LoadingIndicator-after
{
  0%
  {
    transform: rotateX(0) rotateY(0);
  }
  25%
  {
    transform: rotateX(0) rotateY(180deg);
  }
  50%
  {
    transform: rotateX(180deg) rotateY(180deg);
  }
  75%
  {
    transform: rotateX(180deg) rotateY(360deg);
  }
  100%
  {
    transform: rotateX(360deg) rotateY(360deg);
  }
}
