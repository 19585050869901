.PlacePhoto
{
  position: relative;
  background: rgba(215, 221, 255, 0.15) center center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PlacePhoto .Icon
{
  opacity: .3;
}
.PlacePhoto small
{
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2px 4px;
  font-size: 7px;
  background-color: rgb(0 0 0 / .4);
  border-radius: var(--border-radius) 0 var(--border-radius) 0;
  display: flex;
  gap: 4px;
  color: #fff;
  max-width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
}
.PlacePhoto a[href]
{
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
