.TripRoutesDialog .LoadingIndicator
{
  width: 100%;
}
.TripRoutesDialog .DialogContent
{
  grid-template-rows: auto;
}
.TripRoutesDialog .DialogBody
{
  padding: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  border-top: 0;
}
.TripRoutesDialog .Button[type=cancel]
{
  font-size: 12px;
  position: absolute;
  top: 0;
  z-index: 10;
  padding: 8px;
  margin: 0 4px;
}
.TripRoutesDialog[aria-hidden]
{
  transform: translateX(100%);
}
