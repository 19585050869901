.TravelModeListBox > .Control
{
  min-width: auto;
  border: none;
  display: flex;
  gap: var(--gap);
  background-color: transparent;
  padding: 0;
  height: auto;
}
.TravelModeListBox[aria-disabled] > .Control
{
  background-color: transparent;
}
.TravelModeListBox[aria-disabled] .Option
{
  background-color: transparent;
  opacity: .5;
}
.TravelModeListBox:focus-visible .TravelModeOption[aria-selected=true]
{
  border-color: var(--focus-border-color);
}
