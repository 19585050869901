.PlaceLinksBlock > .Inner
{
  overflow: hidden;
}
.PlaceLinksBlock a[href]
{
  display: inline-block;
  text-decoration: underline;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PlaceLinksBlock .Div
{
  margin: 10px 0;
  display: flex;
  grid-gap: 16px;
}
.PlaceLinksBlock .Div .Icon
{
  font-size: 12px;
}
.PlaceLinksBlock .Div[hidden]
{
  display: none;
}
.PlaceLinksBlock.open a[href]
{
  white-space: normal;
  word-wrap: anywhere;
}
