.InfoPlacesFeed,
.InfoPlacesFeed .PlaceCard
{
  margin-top: 16px;
}
.InfoPlacesFeed .LoadingIndicator
{
  height: 200px;
}
.InfoPlacesFeed .PlaceCard
{
  transition: opacity .2s;
  max-width: 100%;
  overflow: hidden;
}
