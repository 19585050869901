.PlaceFeaturesBlock .List
{
  font-size: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
}
.PlaceFeaturesBlock .ListItem
{
  padding: 7px;
  border-radius: var(--border-radius);
  line-height: 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}
