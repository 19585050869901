.MapLocationMarker
{
  --size: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.MapLocationMarker::before
{
  content: '';
  position: absolute;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: var(--accent-color);
  animation: MapLocationMarker 2s infinite;
}
.MapLocationMarker::after
{
  content: '';
  display: block;
  line-height: 0;
  width: var(--size);
  height: var(--size);
  background-color: var(--accent-color);
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0 0 0 / .35);
  position: absolute;
}
@keyframes MapLocationMarker
{
  0%
  {
    transform: scale(1);
    opacity: 1;
  }
  70%
  {
    transform: scale(3);
    opacity: 0;
  }
  to
  {
    transform: scale(1);
    opacity: 0;
  }
}
