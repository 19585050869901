.ProfileScreen
{
  display: grid;
}
.ProfileScreen > .Main
{
  width: 100%;
  overflow: hidden;
}
.ProfileScreen > .Main > .Content
{
  padding: 0 12px;
}
