.TripMainCard
{
  height: 215px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 10px;
  box-sizing: border-box;
}
.TripMainCard .TripPhoto
{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  overflow: hidden;
}
.TripMainCard .TripPhoto::after
{
  content: '';
  background-color: rgb(0 0 0 / .4);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.TripMainCard .Heading,
.TripMainCard .Time
{
  position: relative;
  color: #fff;
}
.TripMainCard .Heading
{
  font-size: 27px;
  line-height: 1.2;
  font-weight: 400;
  margin: 6px 0;
}
.TripMainCard .Time
{
  display: flex;
  align-items: center;
  gap: 7px;
}
.TripMainCard .Time .Icon
{
  font-size: 5px;
}
.TripMainCard .UserPhoto
{
  --user-photo-size: 46px;

  position: absolute;
  right: 14px;
  bottom: calc(0px - var(--user-photo-size) / 2);
}
