.TripCard
{
  --photo-height: 180px;

  line-height: 1.4;
  position: relative;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius);
}
.TripCard .Link
{
  color: inherit;
}
.TripCard .TripPhoto
{
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  height: var(--photo-height);
}
.TripCard .FootBlock
{
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: var(--gap);
  align-items: center;
}
.TripCard .UserPhoto
{
  border: 5px solid #fff;
  align-self: flex-start;
  margin-top: calc(-50% - 5px);
}
.TripCard .MainBlock
{
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 4px;
}
.TripCard .Heading
{
  font-size: inherit;
  font-weight: 500;
}
.TripCard .Time
{
  font-weight: 300;
}
.TripCard .Button[type=menu]
{
  padding: var(--gap);
  font-size: 12px;
}
.TripCard .Icon_important-note_outline_white
{
  position: absolute;
  right: 0;
  top: calc(var(--photo-height) - 2em);
  background-color: rgb(0 0 0 / .2);
  border-radius: var(--border-radius);
  margin: -8px 6px;
}
