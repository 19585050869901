.InfoNavSummary
{
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 12px;
  outline: none;
  font-weight: 500;
  padding: 0 12px;
}
.InfoNavSummary .Icon
{
  font-size: 8px;
  justify-self: flex-end;
}
.InfoNavSummary:has(:nth-child(3))
{
  grid-template-columns: auto auto 1fr;
}
