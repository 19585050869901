.CountryOption
{
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 12px;
  padding: 8px 12px;
}
.CountryOption .Img
{
  width: 30px;
  height: 20px;
  line-height: 0;
}
.CountryOption .Span
{
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
