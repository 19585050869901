.MapCardBlock
{
  position: fixed;
  bottom: 0;
  display: block;
  width: min(100%, var(--max-width));
  transition: bottom .2s;
}
.MapCardBlock[hidden]
{
  bottom: -220px;
}
