.TripRouteItem
{
  padding: 12px 10px;
  box-shadow: var(--card-box-shadow);
  border-radius: 14px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-end;
  gap: 6px 20px;
  background-color: #fff;
  transition: transform .2s;
  user-select: none;
  /*min-height: 110px;*/
  box-sizing: border-box;
}
.TripRouteItem .Div
{
  font-weight: 600;
  font-size: 16px;
  margin-top: 4px;
}
.TripRouteItem .Block
{
  display: flex;
  gap: 6px;
}
.TripRouteItem .Block .Button
{
  font-size: 9px;
  border-radius: 50%;
  background-color: #E7E7E7;
  padding: 4px;
}
.TripRouteItem .Button[type=menu]
{
  font-size: 12px;
  padding: 1px;
  align-self: flex-start;
}
.TripRouteItem .Icon_message_filled
{
  font-size: 12px;
}
.TripRouteItem .TripRoutePreview
{
  font-size: 13px;
}
.TripRouteItem[aria-grabbed]
{
  cursor: grab;
}
.TripRouteItem[aria-grabbed]:active
{
  cursor: grabbing;
}
.TripRouteItem[aria-grabbed=true]
{
  transition: none;
  z-index: 1;
}
