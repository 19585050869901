.Photo
{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.Photo .Icon
{
  opacity: .3;
}
